// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

body {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    line-height: 24px;
    background-color: #efefee;
}
section#search-section {
    background-color: #0f5e9b;
    padding: 30px;
}
section#info {
    padding: 50px 0;
}

section#populair {
    background-color: #f1f5f7;
    padding: 50px 0;
}
section#populair h4 {
    text-align: center;
}

h1 {
    font-weight: 700;
    font-size: 27px;
    margin-bottom: 30px;
}
h2 {
    font-size: 21px;
    font-weight: 700;
}
h3 {
    font-size: 17px;
    font-weight: 700;
}

a {
    text-decoration: none;
    color: #2caaeb;
}
a:hover {
    color: #2caaeb;
}

/* header */
#header {
    background-color: #fff;
    border-top: 4px solid #35a7d7;
    border-bottom: 1px #edeff1 solid;
    margin-bottom: 50px;
}
.navbar {
    padding: 0;

}
.navbar .navbar-brand {
    padding: 15px;
}
.navbar .navbar-collapse {
    border-top: 1px #edeff1 solid;
}
.navbar-nav .nav-item {
    border-left: 4px solid transparent;
}
.navbar-nav .nav-item .nav-link {
    color: #99a8ad;
    font-weight: 700;
    padding: 15px;
    border-bottom: 1px #edeff1 solid;
}
.navbar-nav .nav-item:last-child .nav-link {
    border-bottom: none;
}
.navbar-nav .nav-item.active {
    border-left: 4px solid #35a7d7;
}
.navbar-nav .nav-item.active .nav-link {
    color: #222;
    font-weight: 700;
}
.navbar-toggler {
    font-size: inherit;
    border-radius: 0;
    border-left: 1px solid #edeff1;
    padding: 20px;
    width: 60px;
    height: 55px;
    float: right;
}
.navbar-toggler .toggle-icon {
    display: block;
    height: 2px;
    width: 20px;
    background: #99a8ad;
    margin: 0 auto 5px;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
}
@media only screen and (min-width: 768px) {
    .navbar {
        padding: 15px;
    }
    .navbar .navbar-brand {
        padding-top: 15px;
        padding-bottom: 15px;
        margin-right: 30px;
    }
    .navbar .navbar-collapse {
        border-top: none;
    }
    .navbar-nav .nav-item,
    .navbar-nav .nav-item.active {
        border-left: none;
    }
    .navbar-nav .nav-item .nav-link {
        border-bottom: none;
    }
}




#primary {
    background-color: #fff;
    padding: 30px;
    -webkit-box-shadow: 0 1px 1px 0 rgba(0,0,0,0.2);
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.2);
}
.title-block {
    display: inline-block;
    color: #fff;
    background-color: #35a7d7;
    font-size: 14px;
    padding: 7px 15px;
}
.simple-list {
    padding: 0;
    margin: 0;
}
.simple-list li {
    list-style: none;
}

.province-overview {

}

.province-overview h2 {
    display: block;
    font-size: 14px;
}
.province-overview .read-more {
    display: block;
    color: #35a7d7;
    font-weight: 700;
    margin-top: 8px;
}


/* sections */
.section-list {
    position: relative;
    border-bottom: 1px #eee solid;
    padding-bottom: 20px;
    margin-bottom: 20px;
}
.section-list:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
}
.section-list .section-letter {
    position: absolute;
    left: 0;
    top: 0;
    color: #fff;
    background-color: #35a7d7;
    font-weight: 700;
    padding: 12px;
    line-height: 12px;
    width: 35px;
    height: 35px;
    display: block;
    text-align: center;
    vertical-align: middle;
}

.section-list.alphabetical .section-content {
    padding-left: 50px;
}
.section-list ul {
    padding: 0;
    margin: 0;
}
.section-list ul li {
    list-style: none;
}
.section-list ul li a {
    color: #99a8ad;
}

/* widgets */
.widget {
    color: #99a8ad;
    background-color: #fff;
    padding: 30px;
    margin-bottom: 30px;
    -webkit-box-shadow: 0 1px 1px 0 rgba(0,0,0,0.2);
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.2);
}
.widget .widget-title {
    font-size: 16px;
    font-weight: 700;
    color: #333;
    border-left: 4px #35a7d7 solid;
    padding-left: 5px;
    margin-bottom: 15px;
}


.branch-list {
    margin-bottom: 30px;
}
.branch-list .branch {
    color: #333;
    background-color: #eee;
    border-left: 4px #35a7d7 solid;
    padding: 8px 15px;
    margin-bottom: 30px;
    text-decoration-color: #333;
}

.other-branches {

}

/* footer */
#footer {
    color: #99a8ad;
    background-color: #fff;
    border-top: 1px #ddd solid;
    padding: 30px 0;
    margin-top: 50px;
}
#footer .copyright {
    line-height: 54px;
    vertical-align: middle;
}
#footer .footer-nav {
    text-align: right;
    margin-bottom: 0px;
}
#footer .footer-nav li {
    display: inline-block;
}
#footer .footer-nav li a {
    color: #99a8ad;
    font-weight: 700;
    padding: 15px;
}
#footer .footer-nav li a:last-child {
    padding-right: 0px;
}
